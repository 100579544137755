import React, { ForwardedRef, useCallback, useEffect, useRef, useState } from 'react'
import useModal from '@/hooks/useModal'
import Input from '@/components/content/Input'
import { compact, toNumber, uniq } from 'lodash-es'
import Modal from '@/components/content/Modal'
import TextArea from '@/components/content/TextArea'
import Text from '@/components/content/Text'
import { HStack, VStack } from '@/components/content/Stack'
import { colorSystem } from '@/styles/theme'
import { Utils } from '@/utils/Utils'
import RemixIcon from './RemixIcon'

const MAX_MULTI_INPUT_SIZE = 100

export interface MultipleInputProps<T = string> {
  autoFocus?: boolean
  type?: 'string' | 'number'
  label?: string
  modalTitle: string
  value: T[]
  maxSize?: number
  onChange: (values: T[]) => void
  customDescription?: React.ReactNode
}

function MultipleInputInner<T = string>(props: MultipleInputProps<T>, inputRef: ForwardedRef<HTMLInputElement>) {
  const {
    type = 'string',
    label,
    autoFocus = false,
    modalTitle = '다건 입력',
    maxSize = MAX_MULTI_INPUT_SIZE,
    value = [],
    onChange,
    customDescription,
  } = props

  const isFocused = useRef(autoFocus)
  const { open, onOpenModal, onCloseModal } = useModal()
  const [rawVal, setRawVal] = useState(Array.isArray(value) ? value.join(',') : value)

  const onSyncValue = useCallback(
    (value: T[]) => {
      if (Array.isArray(value) && value.join(',') !== rawVal) {
        setRawVal(value.join(','))
      }
    },
    [rawVal],
  )

  const onChangeInput = useCallback(
    (v: string) => {
      let text = v
      if (!v) text = ''
      else if (type === 'number') text = v.replace(/[^0-9,]/g, '').replace(/(\..*)\./g, '$1')
      else if (type === 'string') text = v

      setRawVal(text)

      const values = compact(text.trim().split(',')).slice(0, maxSize)
      onChangeValue(values, false)
    },
    [type],
  )

  const onChangeValue = (list: string[], syncRaw = true) => {
    if (!list.length || (list.length === 1 && !list[0])) {
      onChange([])

      if (syncRaw) {
        setRawVal('')
      }
    } else {
      const formattedList = list
        .map((text) => {
          const trimmed = text.trim()
          return (type === 'number' ? toNumber(trimmed) : trimmed) as T
        })
        .slice(0, maxSize)

      onChange(formattedList)

      if (syncRaw) {
        setRawVal(formattedList.join(','))
      }
    }
  }

  const onBlurInput = useCallback(
    (text: string) => {
      const values = compact(text.split(',').map((v) => v.trim()))
      onChangeValue(values)
    },
    [onChangeValue],
  )

  useEffect(() => {
    if (!isFocused.current) {
      onSyncValue(value)
    }
  }, [value])

  return (
    <>
      <Input
        block
        label={label}
        ref={inputRef}
        value={rawVal}
        placeholder="다건 입력 시, 우측 + 버튼을 눌러주세요."
        onChange={onChangeInput}
        autoFocus={autoFocus}
        onFocus={() => {
          isFocused.current = true
        }}
        onBlur={() => {
          isFocused.current = false
          onBlurInput(rawVal)
        }}
        onEnter={() => onBlurInput(rawVal)}
        rightIcon={<RemixIcon name="ri-add-fill" onClick={onOpenModal} size={18} />}
      />
      <MultipleInputModal
        type={type}
        open={open}
        onClose={onCloseModal}
        title={modalTitle}
        initValue={rawVal}
        maxSize={maxSize}
        onApply={onChangeValue}
        customDescription={customDescription}
      />
    </>
  )
}

export interface MultipleInputModalProps {
  type?: 'number' | 'string'
  open: boolean
  onClose: () => void
  title: string
  placeholder?: string
  initValue?: string
  maxSize?: number
  onApply: (v: string[]) => void
  customDescription?: React.ReactNode
}

export const MultipleInputModal = React.forwardRef<HTMLTextAreaElement, MultipleInputModalProps>((props, ref) => {
  const { type = 'string', open, onClose, title, maxSize = MAX_MULTI_INPUT_SIZE, initValue, placeholder, onApply, customDescription } = props
  const [value, setValue] = useState('')

  const onChangeAreaVal = useCallback(
    (v: string) => {
      if (!v) {
        setValue('')
      } else if (type === 'number') {
        setValue(v.replace(/[^0-9,\n\r ]/g, '').replace(/(\..*)\./g, '$1'))
      } else if (type === 'string') {
        setValue(v)
      }
    },
    [type],
  )

  const onReset = useCallback(() => {
    setValue('')
  }, [])

  const handleClose = useCallback(() => {
    onReset()
    onClose()
  }, [])

  const onClickApply = () => {
    onApply(
      uniq(
        (value || '')
          .trim()
          .split('\n')
          .filter((value) => value)
          .map((value) => value.trim()),
      ),
    )

    handleClose()
  }

  useEffect(
    function onSetInitVal() {
      if (open && initValue) {
        setValue(
          initValue
            ?.trim()
            .split(',')
            .filter((value) => value)
            .map((value) => value.trim())
            .slice(0, maxSize)
            .join('\n') ?? '',
        )
      }
    },
    [open],
  )

  return (
    <Modal
      size="sm"
      title={title}
      open={open}
      onClose={handleClose}
      buttons={[
        {
          kind: 'secondary',
          content: '초기화',
          onClick: onReset,
        },
        {
          kind: 'primary_light',
          content: '적용',
          onClick: onClickApply,
        },
      ]}
      closeIcon
    >
      <VStack gap={8}>
        {customDescription ? (
          <HStack block gap={4} p={8} bg={colorSystem.bg.filter} style={{ borderRadius: '4px' }}>
            <RemixIcon name="ri-alert-line" size={12} style={{ paddingTop: '2px' }} />
            <Text kind="Body_14_regular" color={colorSystem.text.common.primary}>
              {customDescription}
            </Text>
          </HStack>
        ) : null}
        <TextArea
          ref={ref}
          value={value}
          resize="vertical"
          placeholder={placeholder || '줄바꿈으로 입력하거나 엑셀에서 복사/붙여넣기 해 주세요.'}
          onChange={onChangeAreaVal}
          rows={4}
          cols={40}
        />
        <Text kind="Body_14_regular" color={colorSystem.text.common.tertiary}>
          * 최대 {Utils.getCommaNum(maxSize)}개까지 입력 가능합니다.
        </Text>
      </VStack>
    </Modal>
  )
})

const MultipleInput = React.forwardRef(MultipleInputInner) as <T>(
  props: MultipleInputProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof MultipleInputInner>

export default MultipleInput
