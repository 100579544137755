import React, { CSSProperties, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Loader from '@/components/content/Loader'
import { isEmpty } from 'lodash-es'
import { colors, colorSystem, fontSystem } from '@/styles/theme'
import cx from 'classnames'
import { isNil } from 'lodash'
import { Utils } from '@/utils/Utils'
import { VStack } from '@/components/content/Stack'
import RemixIcon from '@/components/content/RemixIcon'

export type TableColumnAlign = 'left' | 'right' | 'center'

export interface TableColumn<T> {
  key?: keyof T | string
  title?: React.ReactNode
  width?: number | string
  minWidth?: number
  maxWidth?: number
  align?: TableColumnAlign
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellClass?: ((v: any, row: T, idx: number) => string) | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (v: any, row: T, idx: number) => React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (v: any, row: T, idx: number) => void
}

export interface TableProps<T = StringMap> {
  type?: 'v' | 'h'
  className?: string
  m?: string | number
  p?: string | number
  minWidth?: number
  height?: number
  block?: boolean
  noHover?: boolean
  radius?: string
  data: T[]
  alignWithHeader?: boolean
  columns: TableColumn<T>[]
  emptyText?: React.ReactNode
  style?: CSSProperties
  isLoading?: boolean
  onClickRow?: (row: T, idx: number) => void
  emptyClassName?: string
}

function Table<T = StringMap>(props: TableProps<T>) {
  const {
    isLoading = false,
    type = 'v',
    data = [],
    columns = [],
    noHover,
    radius,
    className,
    minWidth,
    height,
    block,
    emptyText,
    style,
    m,
    p,
    alignWithHeader = true,
    onClickRow,
    emptyClassName,
  } = props

  const tableId = useMemo(Utils.uuid, [])
  const getColStyle = useCallback(
    (col: Partial<TableColumn<T>>, position: 'header' | 'body') => {
      const style: CSSProperties = {}
      if (col.minWidth) style.minWidth = `${col.minWidth}px`
      if (col.maxWidth) style.maxWidth = `${col.maxWidth}px`
      if (col.align && (alignWithHeader || (!alignWithHeader && position === 'body'))) style.textAlign = col.align
      if (col.width && (type === 'v' || (type === 'h' && position === 'header'))) {
        const colWidth = typeof col.width === 'number' ? `${col.width}px` : col.width
        style.width = colWidth
        style.minWidth = colWidth
        style.maxWidth = colWidth
      }

      return style
    },
    [type],
  )

  return (
    <TableWrapper $height={height} $radius={radius} className={className} style={style} m={m} p={p}>
      <TableStyled $minWidth={minWidth} $block={block} $noHover={noHover} $radius={radius} $type={type}>
        {type === 'v' && (
          <thead>
            <tr>
              {columns.map((col, idx) => (
                <th style={getColStyle(col, 'header')} key={`table-${tableId}-col-${idx}`}>
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {isEmpty(data) && isLoading ? (
            <tr className="empty-row">
              <td colSpan={columns.length}>
                <EmptyContainer gap={12}>
                  <Loader position="h-center" p={16} />
                  <span>조회중입니다...</span>
                </EmptyContainer>
              </td>
            </tr>
          ) : isEmpty(data) && emptyText ? (
            <tr className="empty-row">
              <td colSpan={columns.length}>
                <EmptyContainer gap={12} className={emptyClassName}>
                  <RemixIcon name="ri-search-line" color={colorSystem.icon.secondary} />
                  {emptyText}
                </EmptyContainer>
              </td>
            </tr>
          ) : (
            data.map((row, rowIdx) => (
              <tr
                key={`table-v-row-${tableId}-${rowIdx}`}
                className={cx('table-row', { 'row-click': onClickRow })}
                onClick={() => onClickRow?.(row, rowIdx)}
                role={onClickRow ? 'button' : undefined}
              >
                {columns.map((col, colIdx) => {
                  const value = row[col.key as keyof T] as React.ReactNode

                  return (
                    <React.Fragment key={`table-v-cell-${tableId}-${col.key?.toString()}-${colIdx}`}>
                      {type === 'h' && <th style={getColStyle(col, 'header')}>{col.title}</th>}
                      <td
                        className={typeof col.cellClass === 'function' ? col.cellClass(value, row, rowIdx) : col.cellClass || ''}
                        style={getColStyle(col, 'body')}
                        onClick={() => col.onClick?.(value, row, rowIdx)}
                        role={col.onClick ? 'button' : undefined}
                      >
                        {col.render ? col.render(value, row, rowIdx) : value}
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            ))
          )}
        </tbody>
      </TableStyled>
    </TableWrapper>
  )
}

const TableWrapper = styled.div<
  Pick<TableProps<UnknownMap>, 'p' | 'm'> & {
    $height?: number
    $radius?: string
  }
>`
  overflow-x: auto;
  height: 100%;
  border-radius: ${({ $radius }) => $radius || '8px'};
  border: 1px solid ${colorSystem.border.table};
  ${({ p }) =>
    !isNil(p) &&
    css`
      padding: ${typeof p === 'string' ? p : `${p}px`};
    `}

  ${({ m }) =>
    !isNil(m) &&
    css`
      margin: ${typeof m === 'string' ? m : `${m}px`};
    `};

  ${({ $height }) =>
    $height &&
    css`
      max-height: ${$height}px;
      overflow-y: auto;
    `};
`

const TableStyled = styled.table<{
  $minWidth?: number
  $block?: boolean
  $noHover?: boolean
  $radius?: string
  $type?: 'v' | 'h'
}>`
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: ${({ $radius }) => $radius || '8px'};
  background-color: ${colors.white};
  overflow: auto;

  thead {
    background-color: ${colorSystem.bg.table.column};
    border-radius: ${({ $radius }) => $radius || '8px'};
  }

  th {
    padding: 8px;
    text-align: left;
    color: ${colorSystem.text.table.column};
    background-color: ${colorSystem.bg.table.column};
    position: sticky;
    top: 0;
    ${fontSystem.Body_12_medium};

    ${({ $type }) =>
      $type === 'v' &&
      css`
        &:not(:last-of-type):after {
          content: '';
          width: 2px;
          height: calc(100% - 18px);
          position: absolute;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${colorSystem.border.divider};
        }
      `}
  }

  > tbody > tr {
    &.table-row {
      ${({ $noHover }) =>
        !$noHover &&
        css`
          &:hover {
            background-color: ${colors.gray200};
          }
        `}

      &.row-click {
        cursor: pointer;
      }
    }
  }

  > tbody > tr:not(.empty-row) > td {
    padding: 8px;
    color: ${colorSystem.text.table.cell};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${fontSystem.Body_12_regular};

    &.empty-text {
      text-align: center;
    }
  }

  > tbody > tr:not(:first-of-type) > td {
    border-top: 1px solid ${colorSystem.border.table};
  }

  ${({ $type }) =>
    $type === 'h' &&
    css`
      width: 100%;

      th {
        padding: 8px 16px;
      }
    `}

  ${({ $block }) =>
    $block &&
    css`
      width: 100% !important;
    `}

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth}px;
    `};
`

const EmptyContainer = styled(VStack)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  height: 100%;
`

export default Table
