import React, { forwardRef, useCallback } from 'react'
import styled, { css, CSSObject } from 'styled-components'
import { colorSystem, fontSystem } from '@/styles/theme'
import { HStack } from '@/components/content/Stack'
import Text from '@/components/content/Text'

export interface TextAreaProps {
  value?: string
  disabled?: boolean
  emptyCheck?: boolean
  autoHeight?: boolean
  placeholder?: string
  rows?: number
  cols?: number
  maxLength?: number
  resize?: 'horizontal' | 'vertical' | 'both' | 'none'
  style?: CSSObject
  onChange?: (v: string) => void
  onBlur?: () => void
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ value, placeholder, resize, rows, cols, maxLength, disabled, emptyCheck, autoHeight, onBlur, onChange, style }, ref) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!onChange) return

        if (emptyCheck && e.currentTarget.value.length && e.currentTarget.value.trim() === '') return
        onChange(e.currentTarget.value || '')
      },
      [onChange, emptyCheck],
    )

    return (
      <TextAreaWrap>
        <StyledTextArea
          ref={ref}
          value={value}
          placeholder={placeholder}
          $autoHeight={autoHeight}
          $resize={resize}
          disabled={disabled}
          rows={rows}
          cols={cols}
          maxLength={maxLength}
          style={style}
          onChange={handleChange}
          onBlur={onBlur}
        />

        {maxLength && !disabled && (
          <TextAreaLength>
            <Text kind="Body_14_regular" color={colorSystem.text.common.tertiary}>
              {value?.length}/{maxLength}
            </Text>
          </TextAreaLength>
        )}
      </TextAreaWrap>
    )
  },
)

const TextAreaWrap = styled.div`
  position: relative;
`

const StyledTextArea = styled.textarea<{ $resize: TextAreaProps['resize']; $autoHeight?: boolean }>`
  padding: 8px;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colorSystem.border.element};
  background-color: ${colorSystem.bg.input.enabled};
  box-sizing: border-box;
  resize: ${({ $resize }) => $resize || 'none'};
  color: ${colorSystem.text.common.primary};
  ${fontSystem.Body_14_regular};

  ${({ $autoHeight }) =>
    $autoHeight
      ? css`
          height: auto;
        `
      : css`
          min-height: 140px;
        `}

  &:disabled {
    background-color: ${colorSystem.bg.input.disabled};
  }

  &:hover,
  &:focus,
  &:active {
    border-color: ${colorSystem.border.element_active};
  }

  &::placeholder {
    color: ${colorSystem.text.common.tertiary};
  }
`

const TextAreaLength = styled(HStack)`
  position: absolute;
  right: 16px;
  bottom: 16px;
`

export default TextArea
